<template>
  <component :is="element" :aria-label="title">
    <span v-for="(part, partIndex) in titleParts" :key="part.toString()">
      <template v-for="(letter, letterIndex) in part" :key="letter">
        <client-only>
          <Vue3Lottie
            v-if="partIndex === firstPartWithIIndex && letterIndex === firstPartLetterWithIIndex"
            ref="animation"
            :loop="false"
            :autoplay="false"
            :animation-data="animationData"
            class="crunchy-i"
          />
          <template v-else>{{ letter }}</template>
        </client-only>
      </template>
      <!-- add a space at the end of every word -->
      <span>&nbsp;</span>
    </span>
  </component>
</template>

<script lang="ts" setup>
import CrunchyAnimation from '@/assets/lottie/crunchyAnimation.json';
import { ref } from 'vue';

const animation = ref(null);
const animationData = ref(CrunchyAnimation);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  element: {
    type: String,
    default: 'h2',
  },
  width: {
    type: Number,
    default: 40,
  },
  height: {
    type: Number,
    default: 72,
  },
});

const titleParts = props.title.split(' ').map((part: string) => part.split(''));
const firstPartWithIIndex = titleParts.findIndex((part: string[]) => part.includes('i'));
const firstPartLetterWithIIndex = titleParts[firstPartWithIIndex]?.findIndex((letter: string) => letter.toLowerCase() === 'i');

const play = (): void => {
  if (!animation.value) {
    return;
  }
  animation.value?.[0]?.play();
};

defineExpose({
  play,
});

</script>

<style lang="scss" scoped>
.crunchy-i {
  margin: 0 0 4px;
  inline-size: 40px;
  block-size: 72px;
}
</style>
